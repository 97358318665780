<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{ $t('set.bindingEmail') }}</div>
			</div>
		</div>
		<div class="cur_account"></div>
		<div class="now_email" v-if="email != ''">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/email-1.png">
			</div>
			<div>
				<span class="email">{{ $t('curEmailNum') }}：</span><span class="email_num">{{ email }}</span>
			</div>
		</div>

		<div class="input_item" v-if="email != ''">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/code.png">
			</div>
			<div class="input">
				<input type="text" :placeholder="$t('login.inputCode')" v-model="oldcode" @blur="verifyCode">
			</div>
			<div class="code">
				<div class="code1" @click="getOldCode" v-if="!isGetCode">{{ $t('login.getCode') }}</div>
				<div class="code2" v-else>{{ $t('login.sendAgain') }}：{{ time }}</div>
			</div>
		</div>

		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/email.png">
			</div>
			<div class="input">
				<input type="text" :placeholder="$t('login.inputEmail')" v-model="user_email" :disabled="is_disable">
			</div>
		</div>

		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/code.png">
			</div>
			<div class="input">
				<input type="text" :placeholder="$t('login.inputCode')" v-model="code" :disabled="is_disable">
			</div>
			<div class="code">
				<div class="code1" @click="getCode" v-if="!isGetCode1">{{ $t('login.getCode') }}</div>
				<div class="code2" v-else>{{ $t('login.sendAgain') }}：{{ time }}</div>
			</div>
		</div>
		<div class="btn" @click="confirmClick">
			{{ $t('confirm') }}
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			user_email: '',
			code: '',
			oldcode: '',
			time: 60,
			isGetCode: false, //是否获取验证码
			isGetCode1: false, //是否获取验证码
			timer: '', //定时器
			timer1: '', //定时器
			email: '',
			is_disable: true,

		}
	},
	destroyed() {
		clearInterval(this.timer);
	},
	mounted() {
		this.getUserInfo()
		// let userinfo = this.$store.state.userinfo;
		// this.email = userinfo.user_email;
		console.log(this.email, 12345);
		if(this.email == ''){
			this.is_disable = false
		}
	},
	methods: {
		// 获取会员信息
		getUserInfo() {
			return new Promise((resolve, reject) => {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						this.email = res.data.user_email
					} else if (res.code == -201) {
					
				} else {
						this.$message.error(res.msg);
					}
					resolve();
				})
			})
		},
		// 获取验证码
		getCode() {
			if (!this.user_email) {
				this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'))
				return;
			}

			let data = {
				type: 7,
				is_test: 1,
				user_email: this.user_email,
			}

			this.$http.getCode(data).then(res => {
				if (res.code == 1) {
					this.$message.success(this.$t('sendSuccess'));
					this.isGetCode1 = true;
					this.timer1 = setInterval(() => {
						if (this.time == 1) {
							clearInterval(this.timer1);
							this.time = 60;
							this.isGetCode1 = false;
						}
						this.time--;
					}, 1000);
				} else {
					this.$message.error(res.msg);
				}
			}).catch((v) => {

			});
		},
		confirmClick() {
			if (!this.user_email) {
				this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'))
				return;
			}


			if (!this.code) {
				this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				return;
			}

			this.$http.bindingEmail({
				user_email: this.user_email,
				code: this.code,
				// step: 2,
				type: 7
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					let newUserinfo = {
						...this.$store.state.userinfo,
						user_email: this.user_email,
					}
					console.log("哈哈哈", newUserinfo);
					this.$store.commit('edituserinfo', newUserinfo);
					this.getUserInfo()
					setTimeout(() => {
						this.clearInput()
					}, 3000);
					// this.$router.push({
					// 	path: '/home/person/personInfor',
					// 	query: {
					// 		curCenterIndex: 0,
					// 	},
					// })
				} else {
					this.$message.error(res.msg);
				}
			})
		},

		// 获取旧邮箱验证码
		getOldCode() {
			if (!this.email) {
				this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'))
				return;
			}

			let data = {
				type: 7,
				is_test: 1,
				user_email: this.email,
			}

			this.$http.getCode(data).then(res => {
				if (res.code == 1) {
					this.$message.success(this.$t('sendSuccess'));
					this.isGetCode = true;
					this.timer = setInterval(() => {
						if (this.time == 1) {
							clearInterval(this.timer);
							this.time = 60;
							this.isGetCode = false;
						}
						this.time--;
					}, 1000);
				} else {
					this.$message.error(res.msg);
				}
			}).catch((v) => {

			});
		},

		/* 校验验证码 */
		verifyCode() {
			if (!this.oldcode) {
				this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				return;
			}
			this.$http.verifyCode({
				user_email: this.email,
				code: this.oldcode,
				type: 7,
			}).then(res => {
				if (res.code == 1) {
					this.is_disable = false
					this.$message.success('请输入新邮箱号')
				} else {
					this.$message.error(res.msg);
				}
			})
		},

		// 清空输入框
		clearInput() {

			this.email = '',
				this.code = '',
				this.oldcode = '',
				this.user_email = '',
				this.is_disable = true,
				clearInterval(this.timer);
			clearInterval(this.timer1);
			this.time = 60;
		},
	}
}
</script>

<style scoped="" lang="less">
.person_infor {
	// width: 926px;
}

.login_top {
	display: flex;
	padding: 32px 40px;
	border-bottom: 1px solid #F3F5F6;

	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
	}
}

.cur_account {
	color: #333333;
	font-size: 16px;
	text-align: left;
	margin-top: 60px;
}

.now_email {
	margin: 32px 40px;
	padding-left: 24px;
	display: flex;
	justify-content: left;
	align-items: center;
	width: 800px;
	height: 56px;
	background: repeating-linear-gradient(to right, rgba(243, 152, 0, 0.2), rgba(243, 152, 0, 0));

	.input_item_icon {
		img {
			width: 20px;
			height: 20px;
		}
	}

	.email {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333FF;
		margin-left: 8px;
	}

	.email_num {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #F39800FF;
	}
}

.dropdown {
	margin-left: 24px;

	&:hover {
		cursor: pointer;
	}
}

.input_item {
	margin-left: 40px;
	margin-bottom: 32px;
	text-align: left;
	display: flex;
	width: 800px;
	padding: 14px 24px;
	border: 1px solid #DEDEDE;
	font-size: 16px;
	position: relative;


	.input_item_icon {
		img {
			width: 20px;
			height: 20px;
		}
	}

	.input {
		padding-left: 16px;

		input {
			width: 400px;
			border: none;
			outline: none;
			font-size: 16px;
		}
	}

	.code {
		position: absolute;
		top: 13px;
		right: 24px;
		color: #4177DD;
		font-size: 16px;

		&:hover {
			cursor: pointer;
		}

	}

	.show_icon {
		position: absolute;
		top: 13px;
		right: 24px;

		img {
			width: 18px;
			height: 18px;
		}
	}
}

.btn {
	margin-left: 40px;
	text-align: center;
	line-height: 48px;
	background: #F39800;
	opacity: 1;
	width: 300px;
	height: 48px;
	border-radius: 4px;
	font-size: 16px;
	color: #FFFFFF;

	&:hover {
		cursor: pointer;
	}
}

.input_item .input input{
	background-color: #fff;
}
</style>
